<template>
    <div>
        <div class="content-header" ref="searchBoxRef">
            <span style="color: #606266; font-size: 14px;">创建时间：</span>
            <el-date-picker
                class="ml12 filter-item input"
                v-model="queryForm.times"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
            <el-input v-model="queryForm.deviceName" class="input" clearable placeholder="请输入设备名称"></el-input>
            <el-button type="plain" class="cotent-btn" @click="handleSearch"
                >搜索</el-button
            >
            <span style="flex: 1;flex-shrink: 0;text-align: right">账户余额: {{ leftAmount }}</span>
        </div>

        <div class="table-list">
            <ETable v-loading="loading" :columns="columns" :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" :cell-style="{}" border>

            </ETable>
            <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>
        </div>
    </div>
</template>
<script>
import {
    Watch,
    Mixins,
    Component,
    InjectReactive
} from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";

import dayjs from "dayjs";

import { getQiaoRenewList, queryService } from "@/api/qiaoDevice";

@Component({
    components: {
        ETable
    }
})
export default class QiaoDeviceDetail extends Mixins(loading, tablePagination, Resize) {
    @InjectReactive() activeName;

    queryForm = {
        times: [],
        deviceName: ""
    }

    columns = [
        {
            label: "创建时间",
            prop: "createTime",
            minWidth: 150,
            align: "center"
        },
        {
            label: "设备名称",
            prop: "deviceName",
            minWidth: 100,
            align: "center",
        },
        {
            label: "关联门店",
            prop: "storeName",
            width: 150,
            align: "center"
        },
        {
            label: "续费开始时间",
            prop: "renewStartDate",
            width: 170,
            align: "center"
        },
        {
            label: "续费成功后到期日",
            prop: "expireDate",
            minWidth: 170,
            align: "center"
        },
        {
            label: "扣款金额",
            prop: "payAmount",
            width: 140,
            align: "center"
        },
        {
            label: "扣款后账户余额",
            prop: "remainAmount",
            width: 140,
            align: "center"
        },
    ]
    tableData = [];

    leftAmount = 0;

    mounted() {
        this.getData();
        this.windowResize();
    }

    getLeftAmount() {
        queryService({
            deviceList: []
        }).then((res) => {
            this.leftAmount = res.data.toFixed(2);
        })
    }
    

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }

    getData() {
        this.showLoading();
        console.log(this.queryForm)
        const params = {
            ...this.queryForm,
            startTime: this.queryForm.times?.[0] ? dayjs(this.queryForm.times[0]).format("YYYY-MM-DD 00:00:00") : undefined,
            endTime: this.queryForm.times?.[1] ? dayjs(this.queryForm.times[1]).format("YYYY-MM-DD 23:59:59") : undefined,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }
        getQiaoRenewList(params).then(res => {
            this.itemTotal = res.data.total;
            this.tableData = res.data.list;
        }).catch(err => {}).finally(() => {
            this.hideLoading();
        })
    }

    @Watch("activeName", { immediate: true })
    activeNameChange() {
        if (this.activeName != "second") return;
        this.getLeftAmount();
    }
}
</script>

<style lang="scss" scoped src="../style.scss"></style>
<style lang="scss" scoped>
.content-header {
    border: none;
}
.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 0 12px;
}
.renew-status-2 {
    color: red;
}

.el-icon-warning-outline {
    color: #666666;

    &:empty::after {
        content: "";
    }
}
</style>
